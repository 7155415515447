import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../Modal/Modal';
import SocialButtons from '../SocialButtons/SocialButtons';

import getCDNImage from '../../utils/getCDNImage';

import styles from './LoginModal.module.scss';

const LoginModal = ({ isOpen, newsletter, setOpen }) => {
  const intl = useIntl();
  const closeModal = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={closeModal}
      closeModal={closeModal}
      className={styles.modalSocialButtons}
      data-testid="login-modal"
      title={intl.formatMessage({ id: 'popups.login.title', defaultMessage: 'Log in or sign up' })}
    >
      <div className={styles.content}>
        <p>
          {!newsletter && (
            <FormattedMessage id="popups.login.subtitle" defaultMessage="Log in or sign up to enjoy many benefits" />
          )}
          {newsletter && (
            <FormattedMessage
              id="popups.subscribed.subtitle"
              defaultMessage="Log in or sign up to apply for the Shops.AE newsletter and stay up to date with the many benefits"
            />
          )}
        </p>

        {!newsletter && (
          <img className={styles.logo} src={getCDNImage(intl.formatMessage({ id: 'popups.login.image' }))} alt="" />
        )}
        {newsletter && (
          <img
            className={styles.logo}
            src={getCDNImage(intl.formatMessage({ id: 'popups.subscribed.image' }))}
            alt=""
          />
        )}

        <SocialButtons />
      </div>
    </Modal>
  );
};

LoginModal.displayName = 'LoginModal';
export default LoginModal;
