import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SocialButton from '../SocialButton/SocialButton';
import { ReactComponent as TwitterIcon } from '../../images/icons/twitter.svg';

import styles from './TwitterButton.module.scss';

const TwitterButton = ({ classList = {}, children, ...props }) => (
  <SocialButton classList={{ root: classnames(styles.root, classList.root) }} Icon={TwitterIcon} {...props}>
    {children}
  </SocialButton>
);

TwitterButton.displayName = 'TwitterButton';
TwitterButton.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
};

export default TwitterButton;
