import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SocialButton from '../SocialButton/SocialButton';
import { ReactComponent as FacebookIcon } from '../../images/icons/facebook.svg';

import styles from './FacebookButton.module.scss';

const FacebookButton = ({ classList = {}, children, ...props }) => (
  <SocialButton classList={{ root: classnames(styles.root, classList.root) }} Icon={FacebookIcon} {...props}>
    {children}
  </SocialButton>
);

FacebookButton.displayName = 'FacebookButton';
FacebookButton.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
};

export default FacebookButton;
