import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SocialButton from '../SocialButton/SocialButton';
import { ReactComponent as GoogleIcon } from '../../images/icons/google.svg';

import styles from './GoogleButton.module.scss';

const GoogleButton = ({ classList = {}, children, ...props }) => (
  <SocialButton classList={{ root: classnames(styles.root, classList.root) }} Icon={GoogleIcon} {...props}>
    {children}
  </SocialButton>
);

GoogleButton.displayName = 'GoogleButton';
GoogleButton.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
};

export default GoogleButton;
