import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SocialButton.module.scss';

const SocialButton = ({ children, classList, Icon, ...props }) => (
  <button className={classnames(styles.root, classList.root)} {...props}>
    <span className={styles.buttonIcon}>
      <Icon />
    </span>
    <span className={styles.buttonText}>{children}</span>
  </button>
);

SocialButton.displayName = 'SocialButton';
SocialButton.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
};

export default SocialButton;
