import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SocialButton from '../SocialButton/SocialButton';
import { ReactComponent as LinkedInIcon } from '../../images/icons/linkedin.svg';

import styles from './LinkedinButton.module.scss';

const LinkedinButton = ({ classList = {}, children, ...props }) => (
  <SocialButton classList={{ root: classnames(styles.root, classList.root) }} Icon={LinkedInIcon} {...props}>
    {children}
  </SocialButton>
);

LinkedinButton.displayName = 'LinkedinButton';
LinkedinButton.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
};

export default LinkedinButton;
