import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../Modal/Modal';
import SocialButtons from '../SocialButtons/SocialButtons';

import styles from './ExpiredSessionModal.module.scss';

const ExpiredSessionModal = ({ isOpen, setOpen }) => {
  const intl = useIntl();
  const closeModal = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={closeModal}
      closeModal={closeModal}
      className={styles.modalSocialButtons}
      data-testid="login-modal"
      title={intl.formatMessage({ id: 'popups.login.title', defaultMessage: 'Log in or sign up' })}
    >
      <div className={styles.content}>
        <p>
          <FormattedMessage id="common.expiredSession" defaultMessage="User session has expired. Please log in again" />
        </p>
        <SocialButtons />
      </div>
    </Modal>
  );
};

export default ExpiredSessionModal;
